import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';

// import { CarouselConfig } from 'ngx-bootstrap/carousel';
@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
  // providers: [
  //   { provide: CarouselConfig, useValue: { interval: 3000, noPause: true, showIndicators: true } }
  // ]
})
export class LeftSidebarComponent implements OnInit {
  images = [];
  imageList: any;
  count: any;
  messageRequest: boolean = true;
  showTooltip: boolean = false;
  notificationdetails: any;
  type: any;
  image: any;
  datetime: any;
  newdata: any;
  countryList = [];
  d: any;
  normalUser: any;
  apiUrl: string;
  public show: boolean = false;
  userType: any = '';
  shouldResetCount: boolean = true;
  datas: any;

  siteLanguage: string = 'EN';
  languageList;

  constructor(
    private __language: LanguageService,
    public translate: TranslateService,
    private __route: Router,
    private __websocket: WebsocketService,
    private notification: NotificationService,
    private __http: HttpClient
  ) {
    this.apiUrl = environment.api_url;
  }

  //
  carouselOptions: OwlOptions = {
    loop: true,
    nav: false,
    autoplay: true,
    autoWidth: true,
    autoHeight: true,
    autoplayTimeout: 2000,
    // dots: false,
    responsive: {
      0: {
        items: 1,
      },
      // 600: {
      //   items: 1,
      // },
      // 1000: {
      //   items: 1,
      // },
    },
  };
  //

  ngOnInit(): void {
    this.getImage();
    this.normalUser = localStorage.getItem('portal_login_data');
    const jsonObject = JSON.parse(this.normalUser);
    let user_type = jsonObject.user_type;
    ////debugger
    if (user_type === 'REGULAR') {
      ////debugger
      this.messageRequest = false;
    }
    this.images = [
      'assets/imgs/slider1.jpg',
      'assets/imgs/slider1.jpg',
      'assets/imgs/slider1.jpg',
      'assets/imgs/slider1.jpg',
      'assets/imgs/slider1.jpg',
      // Add more image URLs here
    ];
    // this.getnotficationlist()

    this.notification.getcounter().subscribe(
      (response) => {
        console.log('Notification count reset successfully');
        this.count = response.data;
      },
      (err) => {
        console.error('Error resetting notification count', err);
      }
    );
    this.listLanguages();

    this.getCountOnreal();
  }

  displayStyle = 'none';

  toggle() {
    console.log('triggred');
    this.show = !this.show;
  }
  close_toggle() {
    this.show = false;
  }

  //   checkdata(){
  //     var d=window.location.reload();
  //     if(this.d){

  //       setTimeout(function() {
  //         this.openPopup();
  //     }, 1800);
  //      }
  // }

  // open popup
  getImage(): Promise<any> {
    return this.__http
      .get(`${this.apiUrl}/admin/get/image`)
      .toPromise()
      .then((user) => {
        this.imageList = user;
        console.log('Images', this.imageList);
        return user; // You can return the user object if needed
      })
      .catch((error) => {
        console.error('Error fetching images', error);
        throw error; // Rethrow the error if needed
      });
  }
  getProfileImage(imagePath: string) {
    if (imagePath) {
      return `${environment.image_url}` + imagePath;
    } else {
      return 'assets/img/profileImg.png';
    }
  }
  openPopup() {
    this.countryList = [];
    this.notification.getnotifications().subscribe(
      (response) => {
        if (response.data.length === 0) {
          alert('There is no notification history');
        } else {
          this.displayStyle = 'block';
          console.log('Notification Data', response.data);
          this.count = response.data.length;
          this.notificationdetails = response.data[0].msg;
          this.type = response.data[0].notification_type;
          this.image = response.data[0].sender_profile;
          this.datetime = response.data[0].createdAt;
          //debugger;
          this.countryList = response.data;
          // let json = JSON.parse(this.normalUser)
          // let notification_list = this.countryList.filter((item)=>{
          //   //debugger
          //   return item.receiver_id== json._id
          // })
          //debugger;
          // console.log("All data", notification_list);

          // Check if the popup is clicked, and reset the counter accordingly
          if (this.shouldResetCount) {
            this.resetNotificationCount();
          }
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getCountOnreal(){
    this.__websocket.socket.on('notification', (data) => {
      ////console.log('EMIT LOGIN informCallStart');

      console.log('data notification =====',data)
    });

    this.__websocket.socket.on('notificationCount', (data) => {
      ////console.log('EMIT LOGIN informCallStart');
      this.count = data.notificationCount;
      console.log('data notificationCount =====',data)
    });
  }

  resetNotificationCount() {
    this.count = 0;
    this.notification.getcounter().subscribe(
      (response) => {
        console.log('Notification count reset successfully');
      },
      (err) => {
        console.error('Error resetting notification count', err);
      }
    );
  }

  // In your other component or wherever you want to reset the count

  // close popup
  closePopup() {
    this.displayStyle = 'none';
  }

  // getnotification(){

  //   // alert("hello");
  //   this.notification.getnotifications()
  //   .subscribe(response => {
  //     console.log("Notification Data",response.data);
  //     this.count=response.data.length
  //     console.log("count",this.count);

  //   },
  //     (err) => {
  //       console.log(err)
  //     })

  // }

  logout() {
    //debugger;
    localStorage.clear();
    this.__route.navigate(['/login']);
    this.__websocket.socket.emit('logout');
    // window.location.reload();
  }

  // to reinitialize the current component without refresh
  reload_component(routeLink) {
    if (this.__route.url == routeLink) {
      this.__route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.__route.navigate([routeLink]);
        ////console.log(routeLink);
      });
    }
  }

  setLanguage(lang) {
    this.translate.use(lang);
    this.siteLanguage = lang;
  }
  listLanguages() {
    this.__language.listLanguages().subscribe(
      (response) => {
        if (response.success == true) {
          // ////console.log(response.data)
          this.languageList = response.data.docs;
        }
      },
      (err) => {
        ////console.log(err)
      }
    );
  }
}

import {
  Component,
  inject,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CallService } from '../services/call/call.service';
import moment, { duration } from 'moment';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { WebsocketService } from '../services/websocket/websocket.service';
import { ToastrService } from 'ngx-toastr';
import { ChatService } from '../services/chat/chat.service';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-caller-wrapper',
  templateUrl: './caller-wrapper.component.html',
  styleUrls: ['./caller-wrapper.component.scss'],
})
export class CallerWrapperComponent implements OnInit {
  // @ViewChild('tipModal') tipModalTemplate!: TemplateRef<any>;
  @ViewChild('content') contentModal!: TemplateRef<any>;
  public agoraClient: any;
  tipAmount: string;
  userType: any;
  tips_values: string | undefined;
  loginInfo: any;
  public remoteUserInfo: any;
  public channelParameters = {
    remoteVideoTrack: null,
    remoteAudioTrack: null,
    localVideoTrack: null,
    localAudioTrack: null,
    remoteUid: null,
  };
  public remotePlayerContainer: any;
  public localPlayerContainer: any;
  agoradetails: any = {
    appId: 'faed64db6f094de5bfeb4cf8bcb71214',
    channel: '',
  };
  remotePlayerType = '';
  microphoneToggle: boolean = false;
  videoToggle: boolean = false;
  videoCallInfo: any;
  callStartTime: any;
  timeToEnd: any;
  callTimerInterval: any;
  calScheduledata: any;
  closeResult = '';
  modalref:any;

  constructor(
    private __websocket: WebsocketService,
    private __chat: ChatService,
    private __user: UserService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.userType = JSON.parse(localStorage.getItem('chatUserData'));
    this.loginInfo = JSON.parse(localStorage.getItem('portal_login_data'));
    const handleVSDKEvents = (eventName, ...args) => {
      switch (eventName) {
        case 'user-published':
          if (args[1] == 'video') {
            this.channelParameters.remoteVideoTrack = args[0].videoTrack;
            this.channelParameters.remoteAudioTrack = args[0].audioTrack;
            this.channelParameters.remoteUid = args[0].uid.toString();

            this.remotePlayerContainer =
              document.getElementById('remote-player');
            if (this.remotePlayerContainer === null) {
              const playerTemplate = `
              <div id="remote-player" class="player" />
              `;
              const parser = new DOMParser();
              const player = parser.parseFromString(
                playerTemplate,
                'text/html'
              );
              this.remotePlayerContainer =
                player.getElementById('remote-player');

              let playerWrapper = document.getElementById('remote-view');
              playerWrapper.append(this.remotePlayerContainer);
            }
            // this.remotePlayerContainer.id = args[0].uid.toString();
            this.channelParameters.remoteUid = args[0].uid.toString();
            this.channelParameters.remoteVideoTrack.play(
              this.remotePlayerContainer
            );

            this.callStartTime = new Date();
          }
          if (args[1] == 'audio') {
            this.channelParameters.remoteAudioTrack = args[0].audioTrack;
            this.channelParameters.remoteAudioTrack.play();
          }
      }
    };
    this.agoraClient = this.initAgora(handleVSDKEvents);

    function getQueryParams(url) {
      const queryParams = {};
      const urlParts = url.split('?');

      if (urlParts.length > 1) {
        const queryString = urlParts[1];
        const paramPairs = queryString.split('&');

        paramPairs.forEach((pair) => {
          const [key, value] = pair.split('=');
          queryParams[key] = decodeURIComponent(value);
        });
      }

      return queryParams;
    }
    ;
    this.videoCallInfo = JSON.parse(localStorage.getItem('videoCallInfo'));
    const queryParams = getQueryParams(window.location.href);
    const channalId = queryParams['channalId'];
    //debugger;
    this.remotePlayerType = queryParams['remotePlayerType'];
    if (channalId) this.join(channalId);

    this.callTimerInterval = setInterval(() => {
      let currentTime = new Date().getTime();
      if (Object.keys(this.calScheduledata).length > 0) {
        let duration = moment.duration(
          moment(this.calScheduledata.newEndTime).diff(currentTime)
        );
        if (duration.hours() == 0 && duration.minutes() < 10) {
          this.timeToEnd = `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
        }
        if (
          duration.hours() <= 0 &&
          duration.minutes() <= 0 &&
          duration.seconds() <= 0
        ) {
          this.callEnd();
        }
      }
    }, 1000);
    this.checkSchedule();
  }


  sendtips() {
    //debugger;
    console.log(this.tips_values);
    let celebId = this.userType._id;
    let data = {
      id: celebId,
      amount: this.tips_values,
    };
    this.__chat.giveTips(data).subscribe((res) => {
      if (res.status == 200) {
        this.toastr.success(res.message);
        this.modalref.dismiss();

      }
    });
  }
  initAgora = (eventsCallback) => {
    let agoraEngine = null;

    const setupAgoraEngine = () => {
      agoraEngine = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
    };

    const attachEvents = () => {
      agoraEngine.on('user-published', async (user, mediaType) => {
        await agoraEngine.subscribe(user, mediaType);
        console.log('subscribe success');
        eventsCallback('user-published', user, mediaType);
      });

      agoraEngine.on('user-unpublished', (user) => {
        console.log(user.uid + 'has left the channel');
      });

      agoraEngine.on('user-left', () => {
        this.cleanUp();
      });
    };

    setupAgoraEngine();
    attachEvents();
    return agoraEngine;
  };

  async checkSchedule() {
    const { sender_id } = this.videoCallInfo;
    const currentTime = new Date().getTime();

    const response = await this.__user.checkSchedule(sender_id).toPromise();
    if (response.status === 200) {
        const data = response.data;

        for (const key in data) {
            const scheduleItem = data[key];

            scheduleItem.newStartTime = new Date(scheduleItem.start_time).getTime();
            scheduleItem.newEndTime = new Date(scheduleItem.end_time).getTime();

            if (currentTime >= scheduleItem.newStartTime && currentTime <= scheduleItem.newEndTime) {
                if (scheduleItem.type && scheduleItem.type === 'CALL') {
                    this.calScheduledata = scheduleItem;
                }
            } else {
                this.calScheduledata = {};
            }
        }
    }
}


  async join(channalId) {
    try {
      await this.agoraClient.join(this.agoradetails.appId, channalId, null);
      // Create a local audio track from the audio sampled by a microphone.
      this.channelParameters.localAudioTrack =
        await AgoraRTC.createMicrophoneAudioTrack();
      // Create a local video track from the video captured by a camera.
      this.channelParameters.localVideoTrack =
        await AgoraRTC.createCameraVideoTrack();
      // Append the local video container to the page body.
      this.localPlayerContainer = document.getElementById('local-player');
      // Publish the local audio and video tracks in the channel.
      await this.agoraClient.publish([
        this.channelParameters.localAudioTrack,
        this.channelParameters.localVideoTrack,
      ]);
      // Play the local video track.
      this.channelParameters.localVideoTrack.play(this.localPlayerContainer);

      // if (this.videoCallInfo.receiver_id === channalId) {
      //   let message = {
      //     sender_id: this.videoCallInfo.receiver_id,
      //     receiver_id: this.videoCallInfo.sender_id,
      //     type: 'answer',
      //   };
      //   this.__websocket.socket.emit('videocall', message);
      // }
    } catch (e) {
      console.log(e)
    }
  }

  muteMic() {
    const { localAudioTrack } = this.channelParameters;
    // this.isMice = !this.localTracks.audioTrack.enabled;
    localAudioTrack.setEnabled(!localAudioTrack.enabled);
    this.microphoneToggle = localAudioTrack.enabled;
  }

  muteVideo() {
    const { localVideoTrack } = this.channelParameters;
    // this.isMice = !this.localTracks.audioTrack.enabled;
    localVideoTrack.setEnabled(!localVideoTrack.enabled);
    this.videoToggle = localVideoTrack.enabled;
  }

  async callEnd() {
    if (this.callStartTime) {
      let startTime =
        this.callStartTime.getHours() +
        ':' +
        String(this.callStartTime.getMinutes()).padStart(2, '0') +
        ':' +
        this.callStartTime.getSeconds();

      let callEndTime = new Date();
      let endTime =
        callEndTime.getHours() +
        ':' +
        String(callEndTime.getMinutes()).padStart(2, '0') +
        ':' +
        callEndTime.getSeconds();

      Promise.all([
        this.agoraClient.leave(),
        new Promise((resolve, reject) => {
          this.__chat
            .saveCall({
              receiver_id: this.videoCallInfo.receiver_id,
              call_start_time: startTime,
              call_end_time: endTime,
            })
            .subscribe(resolve, reject);
        }),
      ]);
    }
    // this.openTipModal

    this.cleanUp();
  }

  async opentips() {
    //debugger;
    this.loginInfo = JSON.parse(localStorage.getItem('portal_login_data'));
    if (this.loginInfo.user_type === 'REGULAR') {
      this.modalref = this.modalService.open(this.contentModal, {
        centered: true,
      });

      // modalRef.result.then(
      //   (result) => {
      //     //debugger
      //     this.sendtips(this.tips_values)
      //     console.log(`Modal closed with result: ${result}`);
      //   },
      //   (reason) => {
      //     console.log(`Modal dismissed with reason: ${reason}`);
      //   }
      // );
    }
  }

  async cleanUp() {
    window.close();
  }
}
